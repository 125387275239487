
interface File extends Blob {
    name: string;
    size: number;
    type: string;
}

export interface UploadedFile {
    name: string;
    size: number;
    type: string;
    content: string;
}

export function readFileIntoMemory(file: File, callback: (a: any) => any) {
    var reader = new FileReader();
    reader.onload = function () {
        callback({
            name: file.name,
            size: file.size,
            type: file.type,
            content: this.result,
        });
    };
    reader.readAsText(file);
}


export function csvToArray(str: string, delimiter = ",") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
        // TODO: refactor out the hack.
        const values = row.split('"' + delimiter + '"');
        const el = headers.reduce(function (object: any, header, index) {
            object[header] = values[index];
            return object;
        }, {});
        return el;
    });

    // return the array
    return arr;
}



export function formatTime(timeInSeconds: number) {

    let timeDisplay = '';
    timeInSeconds = Math.round(timeInSeconds);
    const hours = Math.floor(timeInSeconds / 60 / 60);
    const minutes = Math.floor(timeInSeconds / 60);

    if (hours > 1) {
        timeDisplay = `${hours} hours`;
        if (minutes % 60) {
            timeDisplay += ` ${minutes % 60} minutes`;
        }
    } else if (minutes > 20) {
        timeDisplay = `${minutes} minutes`;
    } else {
        timeDisplay = `${minutes} minute${minutes === 1 ? '' : 's'}`;
        if (timeInSeconds % 60) {
            timeDisplay += ` ${timeInSeconds % 60} seconds`;
        }
    }
    return timeDisplay;
}


export function datestrToDate(datestr: string, dayFirst: boolean) {
    // date in a format like: 10/06/2022
    try {
        datestr = datestr.slice(0, datestr.length - 1);

        let [maybeDay, maybeMonth, maybeYear] = ['', '', ''];

        if (datestr.match(/\d{1,2}\/\d{1,2}\/\d{2,4}/)) {
            [maybeDay, maybeMonth, maybeYear] = datestr.split('/');
        } else if (datestr.match(/\d{1,2}\.\d{1,2}\.\d{2,4}/)) {
            [maybeDay, maybeMonth, maybeYear] = datestr.split('.');
        } else if (datestr.match(/\d{1,2}-\d{1,2}-\d{2,4}/)) {
            [maybeDay, maybeMonth, maybeYear] = datestr.split('-');
        } else {
            console.log(datestr);
            return null;
        }


        let year = Number(maybeYear);
        if (year < 2000) {
            year += 2000;
        }
        if (dayFirst) {
            return new Date(`${year}-${maybeMonth.padStart(2, '0')}-${maybeDay.padStart(2, '0')}T00:00:00.000Z`);
        } else {
            return new Date(`${year}-${maybeDay.padStart(2, '0')}-${maybeMonth.padStart(2, '0')}T00:00:00.000Z`);
        }
    } catch (err) {
        console.log("Error parsing date", err, datestr);
        return null;
    }

}




