import React, { useEffect, useState } from 'react';

import './App.css';

import { readFileIntoMemory, csvToArray, datestrToDate, UploadedFile, formatTime } from './calculator/utils';
import Background from './images/netflix_visual_cropped.jpg';
import justynaImage from './images/justyna_image.jpg';

import logo from './images/logo.png';


const possibleTexts = [
    "do I watch per week?",
    "do I watch per month?",
    "do I watch per day?",
    "have I watched this year?"
];

const DAY_IN_MS = 24 * 60 * 60 * 1000;

function Analysis({ parsedCsv }: { parsedCsv: any }) {
    if (!parsedCsv) {
        return null;
    }
    const currentDate = new Date();

    let dayFirst = false;
    for (const val of parsedCsv) {
        if (!val.Date) {
            continue;
        }
        const datestr = val.Date.slice(1, val.Date.length - 1);
        let delimiter: string;

        if (datestr.includes('/')) {
            delimiter = '/';
        } else if (datestr.includes('.')) {
            delimiter = '.';
        } else if (datestr.includes('-')) {
            delimiter = '-';
        } else {
            continue;
        }

        const [maybeDay] = datestr.split(delimiter);

        if (Number(maybeDay) > 12) {
            dayFirst = true;
            break;
        }
    }

    const videosWithParsedDate = parsedCsv.filter((val: { Date: string }) =>
        val.Date ? true : false
    ).map((val: { Date: string }) => {
        const date = datestrToDate(val.Date, dayFirst);
        return { ...val, Date: date };
    }).filter((val: { Date: Date }) =>
        val.Date ? true : false
    );

    let firstDate = currentDate;
    for (const val of videosWithParsedDate) {
        if (val.Date < firstDate) {
            firstDate = val.Date;
        }
    }

    const videosThisYear = videosWithParsedDate.filter((val: { Date: Date }) => {
        if (val.Date !== null) {
            return val.Date.getFullYear() === currentDate.getFullYear();
        } else {
            return false;
        }
    }
    );

    const videosLast30Days = videosWithParsedDate.filter((val: { Date: Date }) => {
        if (val.Date !== null) {
            return val.Date.getTime() >= currentDate.getTime() - 30 * DAY_IN_MS;
        }
        else {
            return false;
        }
    });

    const averageDuration = 40 * 60;

    if (videosWithParsedDate.length > 0) {
        const totalDaysOfHistory = Math.round((currentDate.getTime() - firstDate.getTime()) / DAY_IN_MS);
        const allTime = formatTime(videosWithParsedDate.length * averageDuration);
        const dayAverage = formatTime(videosWithParsedDate.length * averageDuration / totalDaysOfHistory);
        const weekAverage = formatTime(videosWithParsedDate.length * averageDuration / totalDaysOfHistory * 7);
        const twitterURL = `https://twitter.com/intent/tweet?url=https://netflixcalculator.com&text=Ever wondered how much time you spend on Netflix 🤔?
        %0A%0AI have watched Netflix for ${allTime}, averaging ${weekAverage} per week and ${dayAverage} per day! Huh!
        %0A%0AAnd%20you?%20Find%20out!`
        return (
            <div>
                <h3 className='text-2xl mt-4 mb-2'>Analysis results</h3>
                <p>Found {videosWithParsedDate.length} videos in the watch history. Assuming average 40 minutes per video...</p>

                <h4 className='text-xl mt-4 mb-2'>Recent stats</h4>

                <ul className="list-disc marker:text-red-500 px-4 mt-2 mb-2">

                    <li>{formatTime(videosThisYear.length * averageDuration)} this year so far</li>
                    <li>{formatTime(videosLast30Days.length * averageDuration / 30)} on average per day during last 30 days</li>
                </ul>
                <h4 className='text-xl mt-4 mb-2'>All time stats (over last {totalDaysOfHistory} days)</h4>
                <ul className="list-disc marker:text-red-500 px-4 mt-2 mb-2">
                    <li>{allTime} during all time</li>
                    <li>{dayAverage} on average per day</li>
                    <li>{weekAverage} on average per week</li>
                </ul>

                <div style={{ width: 150 }} className="mt-6">
                    <a className="w-100 flex flex-row justify-center gap-1 rounded-full px-4 py-2 bg-blue-500 text-white font-bold" title="Share on twitter"
                        href={twitterURL}
                        target="_blank" rel="noopener noreferrer">
                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"> <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path></svg>
                        <span className="ml-1">Tweet it</span>
                    </a>

                </div>

                <p className="mt-4 mb-2">Is it more than you would like? Consider installing <a className="underline font-bold" href='https://watchlimits.com' target='blank_'>watchlimits</a>.</p>
            </div>
        );
    } else {
        return (
            <div>
                <h3 className='text-2xl mt-4 mb-2'>Analysis results</h3>
                <p>We didn't find any videos in the watch history, how odd :(.</p>
            </div>
        );
    }

}


function Collapsible(props: { header: string | React.ReactNode, content: string | React.ReactNode }) {

    let [open, setOpen] = useState(false);

    return <div className="w-900" style={{ maxWidth: "600px" }}>

        <div className="bg-white border border-gray-200">
            <h2 className=" mb-0" id="headingTwo">
                <button className="
        bg-gray-100
        w-full
        items-center
        py-2
        px-3
        flex
        justify-between
        text-base text-gray-800 text-center
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button"
                    onClick={() => setOpen(prev => !prev)}
                >
                    {props.header} <span className="material-symbols-outlined">
                        {open ? "expand_less" : "expand_more"}
                    </span>
                </button>
            </h2>
            <div className={open ? "" : "hidden"}>
                <div className="py-4 px-5 max-w-600" style={{ maxWidth: "600px" }}>
                    {props.content}
                </div>
            </div>
        </div>

    </div>
}

function Calculator() {

    const [error, setError]: [string, any] = useState("");
    const [result, setResult]: [string, any] = useState("");
    const [selectedFile, setSelectedFile]: [null | File, any] = useState(null);
    const [parsedCsv, setParsedCsv]: [any, any] = useState(null);

    const uploadFile = (event: any) => {

        event.preventDefault();
        setError("");
        if (selectedFile) {
            readFileIntoMemory(selectedFile, (uploadedFile) => showNetflixResult(uploadedFile));
        } else {
            setError("Please select a csv file.");
        }

    }

    function showNetflixResult(uploadedFile: UploadedFile) {
        try {

            const maybeParsedCsv = csvToArray(uploadedFile.content);
            if (maybeParsedCsv.length < 1) {
                setError("Please select a file that contains at least one record.");
            } else if (!('Title' in maybeParsedCsv[0]) || !('Date' in maybeParsedCsv[0])) {
                console.log(Object.keys(maybeParsedCsv[0]));
                setError("Couldn't find expected csv columns: 'Title, Date'");
            } else {
                setParsedCsv(maybeParsedCsv);
                setResult(result);
            }
        } catch (err) {
            console.log(err);
            setError("Couldn't parse the file, sorry :(.")
            return;
        }
    }

    return (
        <div className='mb-8'>

            <NetflixInfo />
            <div className='flex flex-col'>
                <form id="history-form" className='flex mt-5 mb-6 flex-col lg:flex-row gap-6 justify-between items-center' onSubmit={uploadFile}>

                    <input id="history-input" type="file" accept=".csv" className={`
                    file:cursor-pointer file:bg-red-500 file:border-none file:text-white
                    file:px-4 file:py-2 file:font-bold file:border-red-500 file:mr-4
                    bg-gray-100
                    lg:w-800
                     cursor-pointer border-none border-red-500 mr-2` + (selectedFile ? "" : ' bounce')} onClick={() => {
                            setError("");
                        }}
                        onChange={(e) => {
                            setSelectedFile((e.target.files ? e.target.files[0] : null));
                            setParsedCsv(null);
                        }}
                    ></input>

                    <input className={`bg-red-500 px-4 py-2 rounded-full text-white font-bold cursor-pointer ` + ((selectedFile && !parsedCsv) ? " bounce" : '')}
                        id="history-submit" type="submit" value="Calculate*" />

                </form>
                <p className="text-sm mb-6 mt-0">*Your data is processed locally and not sent to any servers.</p>
            </div>

            <div className={'mt-0 mb-2' + (error ? ' text-red-600' : '')} >
                {error || ((selectedFile && !parsedCsv) ? "Click calculate to see the results!" : '')}
            </div>
            <Analysis parsedCsv={parsedCsv} />
            <p className='mt-5 flex items-center gap-2'>Made by <a className='flex items-center gap-1 font-bold underline' href="https://twitter.com/attilczuk">@attilczuk <img src={justynaImage} alt="Justyna's portrait" width="50px" className='rounded-full' /></a> </p>
        </div >
    );
}


function NetflixInfo() {

    const header = <p className="mt-2">You will need a Netflix history file...</p>;
    const content = (<div>

        <p>

            You can find the watch history by following these steps on the Netflix page:</p>
        <ul className="list-disc marker:text-red-500 px-4 mt-2 mb-2">
            <li>
                Go on the user’s icon - which is at the top right on desktop, or top left on mobile
                then select ‘Account’.
            </li>
            <li>
                Go to ‘My Profile’ and then select ‘Viewing Activity’.
            </li>
            <li>
                Here you will see everything you’ve ever watched on Netflix. You can then download it using ‘Download all’ button and upload to a calculator to get time watched.

            </li>
        </ul>

    </div>

    );
    return <Collapsible header={header} content={content} />

}

function HeroBanner() {

    const [heroText, setHeroText] = useState("");

    useEffect(() => {
        let mounted = true;
        let timerId: ReturnType<typeof setTimeout> | null = null;
        let textNumber = 0;

        let i = 0;
        let fullText = possibleTexts[textNumber];

        if (mounted) {

            const updateText = () => {
                let time = 100;
                i += 1;
                if (i === fullText.length - 1) {
                    time = 1000;
                }
                if (i === fullText.length) {
                    i = 0;
                    textNumber = (textNumber + 1) % possibleTexts.length;

                    fullText = possibleTexts[textNumber];
                }
                if (mounted) {
                    setHeroText(fullText.slice(0, i + 1));
                    setTimeout(() => updateText(), time);
                }
            };

            timerId = setTimeout(updateText, 100);
        }
        return () => {
            mounted = false;
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, []);

    return (
        <div className="hero-overlay flex items-center justify-center flex-col gap-5" style={{
            backgroundImage: `url(${Background}`, height: "300px", backgroundSize: "cover", backgroundPosition: "left bottom", opacity: "0.9"
        }}>
            <div className="text-white px-4 lg:px-8 lg:py-8 py-4 text-3xl lg:text-5xl" style={{ backgroundColor: "rgb(7 4 19 / 74%)" }}>
                <h1 className=" " >How many hours of Netflix </h1>

                <h1><span className='text-red-300'>{heroText}</span><span className='blinking text-red-600'>|</span></h1>
            </div>

        </div>
    );
}

function App() {

    const [open, toggleOpen] = useState(true);


    return (
        <>
            <nav className='header flex items-center justify-center py-4 text-2xl'>
                <img src={logo} alt="logo - calculator on red" width="30px" className='rounded-full mr-2' /> Netflix calculator
            </nav>

            <main className=''>
                <HeroBanner />
                <div className="flex items-center justify-center py-10 px-6 flex-col mb-10">

                    <h1 className="text-3xl mb-4">
                        Let's find out!
                    </h1>
                    <Calculator />
                </div>
                <div className={"fixed bottom-0 flex flex-row bg-[#f3f4f6] border-t py-2 md:py-4 w-full px-2 gap-2 z-50 " + (open ? "" : "hidden")}>
                    <a href="https://watchlimits.com/?ref=netflixcalculator"
                        className=" flex-1 flex flex-row justify-start md:justify-center items-center gap-2 hover:opacity-70 duration-200">
                        <div className="flex flex-col lg:items-center">
                            <div className="font-semibold">Hungry for more? Try <span
                                className=" text-red-600 underline whitespace-nowrap">Watchlimits</span>
                            </div>
                            <div className="text-sm">Watch time insights and limits that work for you!</div>
                        </div>
                    </a>
                    <button className="flex-none btn btn-square btn-ghost" aria-label="Close footer" onClick={() => toggleOpen(false)}>

                        <span className="material-symbols-outlined">close</span>
                    </button>
                </div>
            </main>
        </>

    );
}

export default App;
